



























import { BRow, BCol, BFormInput, BFormGroup } from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  name: "AvInputConfirmeCode",
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
  },
})
export default class AvInputConfirmeCode extends Vue {
  codeLength = 6;
  code: Array<{ id: string; value: string }> = [];

  created() {
    this.setCode();
  }

  @Watch("codeLength")
  setCode() {
    let data: Array<{ id: string; value: string }> = [];

    for (let i = 0; i < this.codeLength; i++) {
      data.push({ id: `confirm-code-${i}`, value: "" });
    }

    this.code = data;
  }

  /**
   * Limitando o input a 1 caracter
   */
  maxLength(e: HTMLElement) {
    return String(e).substring(0, 1);
  }

  /**
   * Retorna o focus
   */
  prevFocus(e: KeyboardEvent, index: number) {
    if (e.key == "Backspace") {
      const inputCode = document.getElementById(`confirm-code-${index - 1}`);

      if (inputCode) {
        inputCode.focus();
      }
    }
  }

  /**
   * Avança o focus
   */
  nextFocus(number: string, index: number) {
    this.code[index].value = number;
    this.$emit("input", this.getValue());
    if (number != "") {
      const inputCode = document.getElementById(`confirm-code-${index + 1}`);

      if (inputCode) {
        inputCode.focus();
      }
    }
  }

  getValue() {
    return this.code.reduce((acc, cur) => {
      return acc + cur.value;
    }, "");
  }
}
