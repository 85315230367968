























import { Component, Prop, Vue } from "vue-property-decorator";

@Component( {
  name: "AvIcon"
} )
export default class AvIcon extends Vue {
  @Prop( { required: true } ) name!: string;
  @Prop() color!: string;
  @Prop() size!: string;
  @Prop() spin!: boolean;
  @Prop() rotate!: string;
  @Prop() flipHorizontal!: boolean;
  @Prop() flipVertical!: boolean;
  @Prop() pulse!: boolean;
  @Prop() bounce!: boolean;
  @Prop() ping!: boolean;
  @Prop() left!: boolean;
  @Prop() right!: boolean;
};
