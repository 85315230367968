






















import { BFormCheckbox } from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component( {
  name: "AvCheckbox",
  components: {
    BFormCheckbox,
  }
} )
export default class AvCheckbox extends Vue {
  @Prop( { default: false } ) showIndeterminate!: boolean;
  @Prop() indeterminateCheck!: boolean;
  @Prop() value!: any;
  @Prop() inputValue!: any;
  @Prop() label!: string;
  @Prop( { default: false } ) switchActive!: boolean;
  @Prop( { default: false } ) disabled!: boolean;

  // Computed
  get checked() {
    if( Array.isArray( this.value ) ) {
      return this.value.includes( this.inputValue );
    }

    return this.value;
  }

  // Data
  localChecked = false;
  indeterminate = false;
  
  // Watch
  @Watch( 'value', { immediate: true } )
  setValue() : void {
    if( Array.isArray( this.value ) ) {
      this.localChecked = this.value.includes( this.inputValue );
    } else this.localChecked = this.value;
  }

  @Watch( 'indeterminateCheck', { immediate: true } )
  setIndeterminateCheck( vl: boolean ) : void {
    this.indeterminate = vl;
  }

  // Functions
  checaChange( args: any ) {
    if( Array.isArray( this.value ) ) {
      if( args ) {
        if( this.value.indexOf( this.inputValue ) === -1 ) this.$emit( "input", [ ...this.value, this.inputValue ] );
      } else {
        let localValue = JSON.parse( JSON.stringify( this.value ) );
        localValue.splice( localValue.indexOf( this.inputValue ), 1 );

        this.$emit( "input", localValue );
      }
    } else {
      this.$emit( "input", args );
    }
  }
};
