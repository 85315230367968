














import { BCard, BCardBody } from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component( {
  name: "AvCard",
  components: { BCard, BCardBody }
} )
export default class AvCard extends Vue {
  @Prop() title!: string;
  @Prop( { default: null } ) subtitle!: string;
  @Prop( { default: true } ) noBody!: boolean;
}
