import { ValidationProvider } from "vee-validate";

export default {
  props: {
    state: { type: Boolean, default: null },
    validate: { type: Boolean, default: false },
    validation: { type: Object, default: () => ({}) },
  },
  components: {
    ValidationProvider,
  },
  methods: {
    computedState(validationProps) {
      if (!this.validate) return this.state;
      else if (typeof this.state === "function") return this.state(validationProps);
      else if (typeof this.state === "boolean") return this.state;
      else return validationProps.errors.length > 0 ? false : null;
    },
  },
};
