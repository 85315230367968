<template>
  <b-form-group :label="labelText" :label-for="$attrs.name || $attrs.id">
    <validation-provider
      :mode="validation.mode"
      :name="$attrs.name || $attrs.id"
      :rules="validation.rules"
      :vid="vid"
      v-slot="validationProps"
    >
      <span class="text-danger tw-text-sm" v-if="validate && !bottomValidation">{{
        errorMessage && validationProps.errors[0] ? errorMessage : validationProps.errors[0]
      }}</span>
      <b-input-group :size="size">
        <!-- Prepend ------------------------------------------------------------------------------------------------------->
        <b-input-group-prepend
          v-if="(icon && iconBefore) || $slots['icon-before']"
          :class="{ 'tw-cursor-pointer': clickIconBefore }"
          @click="clickIconBefore"
        >
          <b-input-group-text :class="{ 'is-invalid': computedState(validationProps) === false }">
            <slot name="icon-before" />
            <np-icon :name="icon" v-if="!$slots['icon-before']"></np-icon>
          </b-input-group-text>
        </b-input-group-prepend>
        <!-- Input --------------------------------------------------------------------------------------------------------->
        <b-form-input
          v-bind="$attrs"
          :placeholder="placeholder"
          :data-vv-validate-on="validation.validateOn"
          :value="value"
          @input="emiteInput"
          :state="computedState(validationProps)"
          v-money="moneyOptions"
          :disabled="disabled"
          :maxlength="maxLength"
          @keydown="emiteKeydown"
          @keyup="(v) => $emit('keyup', v)"
          @change="(v) => $emit('change', v)"
          @blur="emiteBlurEvent"
          ref="formInput"
          :size="!fontSize ? inputSize : ''"
          :class="[fontSize ? fontSize : '', colorInput ? colorInput : '']"
        />
        <!-- Append -------------------------------------------------------------------------------------------------------->
        <b-input-group-append
          @click="clickIconAfter"
          :class="{ 'tw-cursor-pointer': clickIconAfter }"
          v-if="(icon && iconAfter) || $slots['icon-after']"
        >
          <b-input-group-text :class="{ 'is-invalid': computedState(validationProps) === false }">
            <slot name="icon-after" />
            <np-icon :name="icon" v-if="!$slots['icon-after']"></np-icon>
          </b-input-group-text>
        </b-input-group-append>

        <b-input-group-append
          :class="{ 'tw-cursor-pointer': clickIconAfter }"
          v-if="(icon && iconAfterDefault)"
        >
          <b-input-group-text :class="{ 'is-invalid': computedState(validationProps) === false }">
            <slot name="icon-after" />
            <np-icon :name="icon" v-if="!$slots['icon-after']"></np-icon>
          </b-input-group-text>
        </b-input-group-append>
        <!-- --------------------------------------------------------------------------------------------------------->
      </b-input-group>
      <span class="text-danger tw-text-sm" :class="{'tw-absolute': spanErrorAbsolute}"  v-if="(validate && bottomValidation) || validationWithNoObligation">{{
        errorMessage && validationProps.errors[0] ? errorMessage : validationProps.errors[0]
      }}</span>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BIcon,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupText,
} from "bootstrap-vue";
import { VMoney } from "v-money";
import AvIcon from "@/components/av-icon/AvIcon.vue";
import validationMixin from "../validation.mixin.js";

export default {
  data() {
    return {
      inputTimeout: null,
    };
  },
  directives: { money: VMoney },
  mixins: [validationMixin],
  props: {
    value: { type: null },
    label: { type: String, default: undefined },
    icon: { type: String },
    iconBefore: { type: Boolean },
    iconAfter: { type: Boolean },
    iconAfterDefault: { type: Boolean },
    moneyOptions: { type: Object | Boolean },
    placeholder: { type: String },
    size: { type: String, default: "md" },
    fontSize: { type: String, default: "" },
    colorInput: { type: String, default: "" },
    delayInput: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    delayInputIn: {
      type: Number,
    },
    //Specific props
    inputSize: { type: String, default: "md" },
    vid: { type: String },
    clickIconBefore: { type: Function, default: Function },
    clickIconAfter: { type: Function, default: Function },
    maxLength: { type: [String , Number] },
    bottomValidation: { type: Boolean, default: true },
    errorMessage: { type: String },
    validationWithNoObligation: { type: Boolean, default: false },
    inputFocus: { type: Boolean, default: false },
    spanErrorAbsolute: { type: Boolean, default: false },
    
  },
  components: {
    BFormGroup,
    BFormInput,
    BIcon,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
    AvIcon,
  },
  methods: {
    emiteInput(value) {
      if (this.delayInput) {
        if (this.inputTimeout) clearTimeout(this.inputTimeout);

        this.inputTimeout = setTimeout(() => {
          this.$emit("input", value);
        }, this.delayInputIn);
      } else this.$emit("input", value);
    },
    emiteKeydown(value) {
      this.$emit("keydown", value);
    },
    emiteBlurEvent() {
      this.$emit("blurEmitted");
    },
  },
  computed: {
    labelText: {
      get() {
        if (!this.label) return "";

        return this.validation?.rules?.indexOf("required") > -1 ? this.label + " *" : this.label;
      },
    },
  },
  watch: {
    inputFocus(newValue) {
      if (newValue) {
        this.$refs.formInput.$el.focus();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.is-invalid {
  border-color: $red !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
