var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',_vm._g({staticClass:"mdi",class:[
    'mdi-' + _vm.name,
    _vm.spin ? 'mdi-spin' : '',
    _vm.rotate ? 'mdi-rotate-' + _vm.rotate : '',
    _vm.flipHorizontal ? 'mdi-flip-h' : '',
    _vm.flipVertical ? 'mdi-flip-v' : '',
    _vm.pulse ? 'tw-animate-pulse' : '',
    _vm.bounce ? 'tw-animate-bounce' : '',
    _vm.ping ? 'tw-animate-ping' : '',
    _vm.left ? 'tw-mr-1' : '',
    _vm.right ? 'tw-ml-1' : '' ],style:({
    color: _vm.color || 'inherit',
    fontSize: _vm.size || 'inherit',
  })},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }